.my-footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.page-lgn{

}
.page-lgn .area{
 display: flex;
  justify-content: center; /* Alinha o conteúdo horizontalmente no centro */
  align-items: center;    /* Alinha o conteúdo verticalmente no centro */
  height: 100vh;          /* Define a altura da div como 100% da altura da janela */

}

@media (max-width: 767px) {
  .img-fluid{
    display: none;
  }
  .page-lgn .container-fluid div .col-md-9{
display: none;
  }
}