.rc-collapse-header-text div{
    position: relative;
    gap: 5px;
  }


  .pg-l{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
  }



  .rc-collapse-header.selected{
    border-left: solid #512b78 3px;
    background: #f3f3f1;
  }
  .rc-collapse-header.selected svg{
    color: #26274b;
  }
  .rc-collapse-header.selected span{
    color: #512b78;
  }
  .rc-collapse-header:hover{
    background: rgb(0,0,0,0.1)
  }


  .rc-collapse-item:first-child a.selected{
    border-top: solid rgb(0,0,0,0.2) 1px;
   }

   .kphhjc{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 300px;
    height: 85vh;
  }


  .sair{
    position: absolute;
      width: 100%;
    bottom: 0;
      height: 50px;
      border-top: solid #e1e1ef 1px;
      text-align: center;
      cursor: pointer;
    }
    
    .sair div{
      padding: 10px;
    }