.area-404{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  height: 100vh;
}
.area-404 img{
  width: 500px;
  margin-bottom: 20px;
}
.area-404 span{
  font-size: 20px;
}

.area-404 .btn-1{
  position: relative;
  background: #cbe1fe;
  border:0; 
  padding: 13px;
  color: #006dfe;
  margin-top: 15px;
  
 
}
.area-404 .btn-1 svg{
 margin-right: 5px;
}
.area-404 .btn-1:hover{
  background:  #006dfe;
  color: #fff;
}

@media (max-width: 767px) {
  .area-404 img{
    width: 250px;
  }
}