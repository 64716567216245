body{margin:0;}/*!sc*/
main{display:block;}/*!sc*/
h1{font-size:2em;margin:0.67em 0;}/*!sc*/
hr{box-sizing:content-box;height:0;overflow:visible;}/*!sc*/
pre{font-family:monospace,monospace;font-size:1em;}/*!sc*/
a{background-color:transparent;}/*!sc*/
abbr[title]{border-bottom:none;-webkit-text-decoration:underline;text-decoration:underline;-webkit-text-decoration:underline dotted;text-decoration:underline dotted;}/*!sc*/
b,strong{font-weight:bolder;}/*!sc*/
code,kbd,samp{font-family:monospace,monospace;font-size:1em;}/*!sc*/
small{font-size:80%;}/*!sc*/
sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}/*!sc*/
sub{bottom:-0.25em;}/*!sc*/
sup{top:-0.5em;}/*!sc*/
img{border-style:none;}/*!sc*/
button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0;}/*!sc*/
button,input{overflow:visible;}/*!sc*/
button,select{text-transform:none;}/*!sc*/
button,[type="button"],[type="reset"],[type="submit"]{-webkit-appearance:button;}/*!sc*/
button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner{border-style:none;padding:0;}/*!sc*/
button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring{outline:1px dotted ButtonText;}/*!sc*/
fieldset{padding:0.35em 0.75em 0.625em;}/*!sc*/
legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal;}/*!sc*/
progress{vertical-align:baseline;}/*!sc*/
textarea{overflow:auto;}/*!sc*/
[type="checkbox"],[type="radio"]{box-sizing:border-box;padding:0;}/*!sc*/
[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button{height:auto;}/*!sc*/
[type="search"]{-webkit-appearance:textfield;outline-offset:-2px;}/*!sc*/
[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}/*!sc*/
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit;}/*!sc*/
details{display:block;}/*!sc*/
summary{display:list-item;}/*!sc*/
template{display:none;}/*!sc*/
[hidden]{display:none;}/*!sc*/
data-styled.g1[id="sc-global-ecVvVt1"]{content:"sc-global-ecVvVt1,"}/*!sc*/
*,::after,::before{box-sizing:border-box;}/*!sc*/
body{font-family:Manrope,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;font-size:17px;line-height:1.75;background-color:#f8f9fb;color:#26274b;text-rendering:optimizelegibility;-webkit-font-smoothing:antialiased;min-width:320px;overflow-x:hidden;}/*!sc*/
@media (max-width:1024px){body{font-size:16px;}}/*!sc*/
body.no-scroll{overflow-y:hidden;}/*!sc*/
a{-webkit-text-decoration:none;text-decoration:none;color:inherit;}/*!sc*/
ul{margin:0;padding:0;}/*!sc*/
li{list-style-type:none;}/*!sc*/
h1,h2,h3,h4,h5,h6{font-family:Space Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;line-height:1.25;font-weight:700;}/*!sc*/
h1,h2,h3{margin:0 0 16px;}/*!sc*/
h4,h5,h6{margin:0 0 8px;}/*!sc*/
h1{font-size:24px;-webkit-letter-spacing:-0.5px;-moz-letter-spacing:-0.5px;-ms-letter-spacing:-0.5px;letter-spacing:-0.5px;}/*!sc*/
@media (max-width:768px){h1{font-size:22px;}}/*!sc*/
@media (max-width:414px){h1{font-size:21px;}}/*!sc*/
h2{font-size:21px;-webkit-letter-spacing:-0.5px;-moz-letter-spacing:-0.5px;-ms-letter-spacing:-0.5px;letter-spacing:-0.5px;}/*!sc*/
@media (max-width:768px){h2{font-size:19px;}}/*!sc*/
@media (max-width:414px){h2{font-size:18px;}}/*!sc*/
h3{font-size:18px;-webkit-letter-spacing:-0.5px;-moz-letter-spacing:-0.5px;-ms-letter-spacing:-0.5px;letter-spacing:-0.5px;}/*!sc*/
@media (max-width:768px){h3{font-size:17px;}}/*!sc*/
h4,h5,h6{font-size:16px;}/*!sc*/
button:active,input:active,button:focus,input:focus{outline:none;}/*!sc*/
hr{height:1px;background-color:#e1e1ef;border:none;}/*!sc*/
input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active{-webkit-box-shadow:0 0 0 64px #ffffff inset !important;-webkit-text-fill-color:#26274b !important;border-radius:8px;}/*!sc*/
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}/*!sc*/
input[type=number]{-moz-appearance:textfield;}/*!sc*/
#nprogress .bar{background:#474bff !important;}/*!sc*/
#nprogress .peg{box-shadow:0 0 10px #474bff,0 0 5px #474bff !important;}/*!sc*/
#nprogress .spinner{display:none !important;}/*!sc*/
.__react_component_tooltip{background-color:#26274b;color:#ffffff;}/*!sc*/
.scroll-to{position:relative;top:-64px;}/*!sc*/
@media (max-width:768px){.scroll-to{top:-56px;}}/*!sc*/
.scroll-to-with-offset{position:relative;top:-88px;}/*!sc*/
@media (max-width:768px){.scroll-to-with-offset{top:-80px;}}/*!sc*/
@media screen{.print{display:none;}}/*!sc*/
@media print{body,body *{visibility:hidden;background-color:#ffffff;}.print,.print *{visibility:visible;}.print{position:absolute;left:0;top:0;}}/*!sc*/
@media (max-width:1024px){.tablet-hidden{display:none !important;}}/*!sc*/
.tablet-visible{display:none !important;}/*!sc*/
@media (max-width:1024px){.tablet-visible{display:block !important;}}/*!sc*/
@media (max-width:768px){.mobile-hidden{display:none !important;}}/*!sc*/
.mobile-visible{display:none !important;}/*!sc*/
@media (max-width:768px){.mobile-visible{display:block !important;}}/*!sc*/
@media (max-width:1024px){#bmc-wbtn{display:none !important;}}/*!sc*/
data-styled.g2[id="sc-global-cRXHFd1"]{content:"sc-global-cRXHFd1,"}/*!sc*/
.eqKVPw{max-width:1050px;width:100%;margin:0 auto;padding:0 40px;}/*!sc*/
@media (max-width:768px){.eqKVPw{padding:0 24px;max-width:100%;}}/*!sc*/
@media (max-width:414px){.eqKVPw{padding:0 16px;}}/*!sc*/
data-styled.g3[id="sc-52bcfac8-0"]{content:"eqKVPw,"}/*!sc*/
.gnDYAQ a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}/*!sc*/
.gnDYAQ a img{height:34px;}/*!sc*/
@media (max-width:768px){.gnDYAQ a img{height:32px;}}/*!sc*/
.gnDYAQ a.mini img{height:24px;}/*!sc*/
data-styled.g4[id="sc-d4f84a7-0"]{content:"gnDYAQ,"}/*!sc*/
.gArjJw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:48px 0;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
@media (max-width:768px){.gArjJw{padding:32px 0;}}/*!sc*/
@media (max-width:414px){.gArjJw{padding:24px 0;}}/*!sc*/
data-styled.g5[id="sc-f1cdfd-0"]{content:"gArjJw,"}/*!sc*/
.eWbhhP{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}/*!sc*/
@media (max-width:1024px){.eWbhhP{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}/*!sc*/
data-styled.g6[id="sc-f1cdfd-1"]{content:"eWbhhP,"}/*!sc*/
.ieQHVG{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}/*!sc*/
@media (max-width:1024px){.ieQHVG{-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;margin:24px 0;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}}/*!sc*/
.ieQHVG .menu-item{font-size:13px;color:#636388;white-space:nowrap;margin:0 16px;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.ieQHVG .menu-item:hover{color:#26274b;}/*!sc*/
@media (max-width:768px){.ieQHVG .menu-item{margin:8px;}}/*!sc*/
data-styled.g7[id="sc-f1cdfd-2"]{content:"ieQHVG,"}/*!sc*/
.cTZIJv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:13px;color:#636388;white-space:nowrap;}/*!sc*/
.cTZIJv i{color:#ff003d;margin:0 5px;font-size:20px;}/*!sc*/
data-styled.g8[id="sc-f1cdfd-3"]{content:"cTZIJv,"}/*!sc*/
.htPSqG{box-sizing:border-box;margin:0;min-width:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-right:16px;}/*!sc*/
.gjkaQD{box-sizing:border-box;margin:0;min-width:0;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding-left:32px;padding-right:32px;}/*!sc*/
.kzUpfE{box-sizing:border-box;margin:0;min-width:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}/*!sc*/
.bqOpTS{box-sizing:border-box;margin:0;min-width:0;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}/*!sc*/
.kslGQD{box-sizing:border-box;margin:0;min-width:0;margin-left:-16px;margin-right:-16px;}/*!sc*/
.dXREbI{box-sizing:border-box;margin:0;min-width:0;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}/*!sc*/
.bGaasd{box-sizing:border-box;margin:0;min-width:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:12px;padding-right:12px;margin-right:8px;}/*!sc*/
.dQHGwq{box-sizing:border-box;margin:0;min-width:0;padding-left:8px;}/*!sc*/
.hHgGmU{box-sizing:border-box;margin:0;min-width:0;}/*!sc*/
.ihRWtT{box-sizing:border-box;margin:0;min-width:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:-8px;margin-right:-8px;}/*!sc*/
.fqNkap{box-sizing:border-box;margin:0;min-width:0;height:100%;padding-left:8px;padding-right:8px;}/*!sc*/
.hFQohi{box-sizing:border-box;margin:0;min-width:0;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}/*!sc*/
@media screen and (min-width:769px){.hFQohi{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}/*!sc*/
.hPxcLQ{box-sizing:border-box;margin:0;min-width:0;margin-top:16px;}/*!sc*/
@media screen and (min-width:769px){.hPxcLQ{margin-top:0;}}/*!sc*/
.chsOCH{box-sizing:border-box;margin:0;min-width:0;padding-left:0;}/*!sc*/
@media screen and (min-width:769px){.chsOCH{padding-left:8px;}}/*!sc*/
.dtVwYC{box-sizing:border-box;margin:0;min-width:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:16px;}/*!sc*/
.keENbF{box-sizing:border-box;margin:0;min-width:0;margin-right:16px;}/*!sc*/
.bzttap{box-sizing:border-box;margin:0;min-width:0;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-top:8px;}/*!sc*/
.kLahrV{box-sizing:border-box;margin:0;min-width:0;margin-bottom:8px;}/*!sc*/
.idBBmR{box-sizing:border-box;margin:0;min-width:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:16px;margin-left:-4px;margin-right:-4px;}/*!sc*/
@media screen and (min-width:769px){.idBBmR{margin-left:-12px;margin-right:-12px;}}/*!sc*/
.VBDpA{box-sizing:border-box;margin:0;min-width:0;-webkit-flex:1;-ms-flex:1;flex:1;padding-left:4px;padding-right:4px;}/*!sc*/
@media screen and (min-width:769px){.VBDpA{padding-left:12px;padding-right:12px;}}/*!sc*/
.hSIxCX{box-sizing:border-box;margin:0;min-width:0;padding-left:4px;padding-right:4px;}/*!sc*/
@media screen and (min-width:769px){.hSIxCX{padding-left:12px;padding-right:12px;}}/*!sc*/
.hCeCcd{box-sizing:border-box;margin:0;min-width:0;margin-top:40px;}/*!sc*/
.dVIZEG{box-sizing:border-box;margin:0;min-width:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:16px;}/*!sc*/
.kKpiJc{box-sizing:border-box;margin:0;min-width:0;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-left:-12px;margin-right:-12px;}/*!sc*/
.krwRCI{box-sizing:border-box;margin:0;min-width:0;width:100%;padding-left:12px;padding-right:12px;margin-bottom:16px;}/*!sc*/
@media screen and (min-width:769px){.krwRCI{width:50%;margin-bottom:24px;}}/*!sc*/
@media screen and (min-width:1025px){.krwRCI{width:33.33333333333333%;}}/*!sc*/
.hSJATd{box-sizing:border-box;margin:0;min-width:0;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:100%;}/*!sc*/
data-styled.g9[id="sc-bcXHqe"]{content:"htPSqG,gjkaQD,kzUpfE,bqOpTS,kslGQD,dXREbI,bGaasd,dQHGwq,hHgGmU,ihRWtT,fqNkap,hFQohi,hPxcLQ,chsOCH,dtVwYC,keENbF,bzttap,kLahrV,idBBmR,VBDpA,hSIxCX,hCeCcd,dVIZEG,kKpiJc,krwRCI,hSJATd,"}/*!sc*/
.bbLVwL{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}/*!sc*/
data-styled.g10[id="sc-gswNZR"]{content:"bbLVwL,"}/*!sc*/
.iqxPPW{height:64px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 16px;}/*!sc*/
@media (max-width:1024px){.iqxPPW{height:56px;padding:0 8px;}}/*!sc*/
.iqxPPW svg{width:22px;min-width:22px;height:29px;}/*!sc*/
@media (max-width:1024px){.iqxPPW svg{width:18px;min-width:18px;height:24px;}}/*!sc*/
.iqxPPW:hover svg{-webkit-animation:OBGVY 0.25s ease-in 2;animation:OBGVY 0.25s ease-in 2;}/*!sc*/
data-styled.g11[id="sc-ed7ce5bb-0"]{content:"iqxPPW,"}/*!sc*/
.eTinNf{background:transparent;font-weight:500;-webkit-transition:0.25s;transition:0.25s;cursor:pointer;}/*!sc*/
.eTinNf:hover{background:#f1f2fb;color:#474bff;}/*!sc*/
.eTinNf i{line-height:0;font-size:22px;}/*!sc*/
@media (max-width:1024px){.eTinNf i{font-size:32px;margin-left:16px;}}/*!sc*/
data-styled.g15[id="sc-4fa535bf-0"]{content:"eTinNf,"}/*!sc*/
.bZZmFg{position:relative;}/*!sc*/
.bZZmFg .menu-link.active{color:#474bff;font-weight:600;pointer-events:none;cursor:default;}/*!sc*/
.bZZmFg .menu-item-title{font-family:Space Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;font-weight:500;height:64px;-webkit-transition:0.25s;transition:0.25s;padding:0 16px;cursor:pointer;}/*!sc*/
@media (max-width:1200px){.bZZmFg .menu-item-title{padding:0 12px;}}/*!sc*/
.bZZmFg .menu-item-title i{font-size:24px;}/*!sc*/
.bZZmFg .menu-item-title .left-i,.bZZmFg .menu-item-title .right-i{font-size:20px;}/*!sc*/
.bZZmFg .menu-item-title .left-i{margin-right:4px;}/*!sc*/
.bZZmFg .menu-item-title .right-i{margin-left:4px;}/*!sc*/
.bZZmFg .submenu{position:absolute;top:calc(100% - 8px);left:50%;-webkit-transform:translateX(-50%) rotateX(90deg);-ms-transform:translateX(-50%) rotateX(90deg);transform:translateX(-50%) rotateX(90deg);-webkit-transform-origin:center top;-ms-transform-origin:center top;transform-origin:center top;background-color:#ffffff;border:1px solid #e1e1ef;box-shadow:0 8px 12px rgba(71,75,255,0.075);border-radius:8px;opacity:0;pointer-events:none;-webkit-transition:0.2s;transition:0.2s;overflow:hidden;}/*!sc*/
.bZZmFg .submenu .submenu-item{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;padding:8px 16px;-webkit-transition:background-color 0.25s,color 0.25s;transition:background-color 0.25s,color 0.25s;font-size:16px;font-weight:600;}/*!sc*/
.bZZmFg .submenu .submenu-item a{width:100%;}/*!sc*/
.bZZmFg .submenu .submenu-item i{font-size:20px;color:#474bff;margin-right:8px;}/*!sc*/
.bZZmFg .submenu .submenu-item img{height:20px;margin-right:12px;object-fit:contain;}/*!sc*/
.bZZmFg .submenu .submenu-item .tool-category-icon{width:36px;height:36px;object-fit:contain;margin-right:8px;margin-left:-8px;}/*!sc*/
.bZZmFg .submenu .submenu-item.link:hover{background-color:rgba(241,242,251,0.5);color:#474bff;}/*!sc*/
.bZZmFg .submenu .submenu-item + .submenu-item{border-top:1px solid rgba(225,225,239,0.25);}/*!sc*/
.bZZmFg:hover .menu-item-title{color:#474bff;}/*!sc*/
.bZZmFg:hover .submenu{-webkit-transform:translateX(-50%) rotateX(0);-ms-transform:translateX(-50%) rotateX(0);transform:translateX(-50%) rotateX(0);opacity:1;pointer-events:visible;}/*!sc*/
data-styled.g17[id="sc-8ebbe9da-0"]{content:"bZZmFg,"}/*!sc*/
.bNuIyr{position:relative;width:400px;height:64px;}/*!sc*/
@media (max-width:1024px){.bNuIyr{display:none;}}/*!sc*/
.bNuIyr .search{font-size:20px;position:absolute;left:12px;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);pointer-events:none;}/*!sc*/
.bNuIyr input{width:100%;height:42px;background-color:#f1f2fb;border:2px solid rgba(225,225,239,0.5);border-radius:8px;padding:0 40px;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.bNuIyr input::-webkit-input-placeholder{color:#636388;}/*!sc*/
.bNuIyr input::-moz-placeholder{color:#636388;}/*!sc*/
.bNuIyr input:-ms-input-placeholder{color:#636388;}/*!sc*/
.bNuIyr input::placeholder{color:#636388;}/*!sc*/
.bNuIyr input:focus{border:2px solid #474bff;}/*!sc*/
.bNuIyr input:focus + .search{color:#474bff;}/*!sc*/
.bNuIyr .clear-button{position:absolute;top:50%;right:8px;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);background-color:rgba(71,75,255,0.1);color:rgba(71,75,255,0.75);padding:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border:none;width:24px;height:24px;border-radius:50%;font-size:20px;opacity:0;pointer-events:none;-webkit-transition:0.1s;transition:0.1s;cursor:pointer;}/*!sc*/
.bNuIyr .clear-button.active{opacity:1;pointer-events:visible;}/*!sc*/
data-styled.g18[id="sc-b2282c19-0"]{content:"bNuIyr,"}/*!sc*/
.kBmvFA{display:none !important;}/*!sc*/
@media (max-width:1024px){.kBmvFA{display:block !important;height:100%;position:relative;background:transparent;border:none;padding:0;cursor:pointer;}@media (max-width:414px){.kBmvFA{position:static;}}}/*!sc*/
.kBmvFA .results{top:calc(100% - 4px);right:-40px;left:unset;-webkit-transform:rotateX(90deg);-ms-transform:rotateX(90deg);transform:rotateX(90deg);width:360px;}/*!sc*/
@media (max-width:414px){.kBmvFA .results{width:100vw;right:0;}}/*!sc*/
.kBmvFA .results.open{-webkit-transform:rotateX(0);-ms-transform:rotateX(0);transform:rotateX(0);}/*!sc*/
.kBmvFA .results .mobile-search-input{width:100%;padding:8px;}/*!sc*/
.kBmvFA .results .mobile-search-input input{width:100%;height:40px;padding:0 16px;border:1px solid #e1e1ef;border-radius:8px;}/*!sc*/
data-styled.g19[id="sc-33cc82a-0"]{content:"kBmvFA,"}/*!sc*/
.eHFHvR{font-size:27px;position:relative;top:3px;color:#26274b;-webkit-transition:0.2s;transition:0.2s;}/*!sc*/
.eHFHvR.active{color:#474bff;}/*!sc*/
data-styled.g20[id="sc-33cc82a-1"]{content:"eHFHvR,"}/*!sc*/
.jtoCll{position:relative;margin:0 auto;padding-top:32px;padding-bottom:32px;}/*!sc*/
@media (max-width:768px){.jtoCll{padding-top:24px;padding-bottom:24px;}}/*!sc*/
data-styled.g22[id="sc-ec7d64aa-0"]{content:"jtoCll,"}/*!sc*/
.ezOZLT{font-size:24px;}/*!sc*/
@media (max-width:768px){.ezOZLT{font-size:22px;}}/*!sc*/
@media (max-width:414px){.ezOZLT{font-size:21px;}}/*!sc*/
.ezOZLT .passive{color:#636388;}/*!sc*/
data-styled.g24[id="sc-ec7d64aa-2"]{content:"ezOZLT,"}/*!sc*/
.dnzoRi{position:relative;width:36px;height:36px;min-width:36px;min-height:36px;border-radius:50%;border:0.5px solid #e1e1ef;}/*!sc*/
.dnzoRi.loading{-webkit-animation:kyMnUB 2s infinite linear;animation:kyMnUB 2s infinite linear;background:linear-gradient(to right,#f1f2fb 4%,#e4e6f7 25%,#f1f2fb 36%);background-size:1000px 100%;}/*!sc*/
.dnzoRi .svg-image{border-radius:50%;overflow:hidden;}/*!sc*/
.dnzoRi .svg-image .error-placeholder{background:#ffffff;}/*!sc*/
.dnzoRi .svg-image .error-placeholder .avatar-placeholder{width:100%;height:100%;}/*!sc*/
data-styled.g29[id="sc-ec339472-0"]{content:"dnzoRi,"}/*!sc*/
.ivGlEj{position:absolute;top:0;right:0;bottom:0;left:0;}/*!sc*/
.ivGlEj span{-webkit-transform:scale(0);-ms-transform:scale(0);transform:scale(0);border-radius:100%;position:absolute;opacity:0.75;background-color:rgba(225,225,239,0.5);-webkit-animation-name:ripple;animation-name:ripple;-webkit-animation-duration:850ms;animation-duration:850ms;}/*!sc*/
@-webkit-keyframes ripple{to{opacity:0;-webkit-transform:scale(2);-ms-transform:scale(2);transform:scale(2);}}/*!sc*/
@keyframes ripple{to{opacity:0;-webkit-transform:scale(2);-ms-transform:scale(2);transform:scale(2);}}/*!sc*/
.jLqXrx{position:absolute;top:0;right:0;bottom:0;left:0;}/*!sc*/
.jLqXrx span{-webkit-transform:scale(0);-ms-transform:scale(0);transform:scale(0);border-radius:100%;position:absolute;opacity:0.75;background-color:rgba(255,255,255,0.5);-webkit-animation-name:ripple;animation-name:ripple;-webkit-animation-duration:850ms;animation-duration:850ms;}/*!sc*/
@-webkit-keyframes ripple{to{opacity:0;-webkit-transform:scale(2);-ms-transform:scale(2);transform:scale(2);}}/*!sc*/
@keyframes ripple{to{opacity:0;-webkit-transform:scale(2);-ms-transform:scale(2);transform:scale(2);}}/*!sc*/
data-styled.g30[id="sc-b4df0171-0"]{content:"ivGlEj,jLqXrx,"}/*!sc*/
.gdvIzy{position:relative;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;height:100%;}/*!sc*/
.gdvIzy .profile{position:relative;height:100%;padding-left:16px;}/*!sc*/
@media (max-width:1024px){.gdvIzy .profile{padding:0;}}/*!sc*/
.gdvIzy .profile .header-avatar,.gdvIzy .profile .loading-avatar{cursor:pointer;}/*!sc*/
@media (max-width:1024px){.gdvIzy .profile .header-avatar,.gdvIzy .profile .loading-avatar{width:28px;height:28px;min-width:28px;min-height:28px;}}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu{position:absolute;top:calc(100% - 8px);right:0;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;background:#ffffff;border-radius:8px;box-shadow:0 8px 12px rgba(71,75,255,0.075);border:1px solid #e1e1ef;opacity:0;pointer-events:none;overflow:hidden;-webkit-transition:opacity 0.2s ease-in-out;transition:opacity 0.2s ease-in-out;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu.open{opacity:1;pointer-events:visible;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-header{min-width:200px;padding:24px;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-header .user-info{max-width:200px;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-header .user-info *{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-header .user-info .name{font-family:Space Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;font-size:20px;font-weight:700;line-height:1.2;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-header .user-info .email{font-size:14px;color:#73738f;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-item{width:100%;padding:12px 24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:#ffffff;border:none;white-space:nowrap;border-top:1px solid rgba(225,225,239,0.5);-webkit-transition:background-color 0.25s,color 0.25s;transition:background-color 0.25s,color 0.25s;cursor:pointer;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-item i{font-size:20px;margin-right:8px;}/*!sc*/
.gdvIzy .profile .avatar-wrapper .profile-menu .profile-menu-item:hover{background:rgba(241,242,251,0.5);color:#474bff;font-weight:500;}/*!sc*/
.gdvIzy .sign-in-button{position:relative;font-size:16px;padding:0 20px 0 16px;margin-left:16px;height:40px;border-radius:20px;background:#474bff;color:#ffffff;border:none;-webkit-transition:0.25s;transition:0.25s;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;overflow:hidden;cursor:pointer;}/*!sc*/
@media (max-width:1024px){.gdvIzy .sign-in-button{margin-left:0;padding:0;background:transparent;color:#26274b;}}/*!sc*/
.gdvIzy .sign-in-button:hover{background:#3236fd;}/*!sc*/
@media (max-width:1024px){.gdvIzy .sign-in-button:hover{background:transparent;}}/*!sc*/
.gdvIzy .sign-in-button .sign-in-icon{width:22px;height:22px;}/*!sc*/
@media (max-width:1024px){.gdvIzy .sign-in-button .sign-in-icon{display:none;}}/*!sc*/
.gdvIzy .sign-in-button .sign-in{-webkit-letter-spacing:0.25px;-moz-letter-spacing:0.25px;-ms-letter-spacing:0.25px;letter-spacing:0.25px;font-weight:500;margin-left:8px;}/*!sc*/
@media (max-width:1024px){.gdvIzy .sign-in-button .sign-in{display:none;}}/*!sc*/
.gdvIzy .sign-in-button .mobile-sign-in-icon{display:none;}/*!sc*/
@media (max-width:1024px){.gdvIzy .sign-in-button .mobile-sign-in-icon{display:block;font-size:27px;}}/*!sc*/
data-styled.g31[id="sc-3bd5624e-0"]{content:"gdvIzy,"}/*!sc*/
.jkkBkE{height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin:0 8px;}/*!sc*/
@media (max-width:1024px){.jkkBkE{display:none;}}/*!sc*/
.jkkBkE .product-finder{font-family:Space Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;width:-webkit-max-content;width:-moz-max-content;width:max-content;height:40px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 12px;border-radius:8px;-webkit-transition:0.2s;transition:0.2s;}/*!sc*/
.jkkBkE .product-finder i{font-size:22px;margin-right:8px;}/*!sc*/
.jkkBkE .product-finder .label{font-size:16px;font-weight:600;}/*!sc*/
.jkkBkE .product-finder .badge{margin-left:8px;background-color:#474bff;color:#ffffff;font-size:10px;font-weight:600;padding:1px 4px;border-radius:4px;-webkit-transition:0.2s;transition:0.2s;}/*!sc*/
.jkkBkE .product-finder:hover{background:#474bff;border-color:#474bff;color:#ffffff;}/*!sc*/
.jkkBkE .product-finder:hover .badge{background-color:#ffffff;color:#474bff;}/*!sc*/
data-styled.g32[id="sc-277ce8cb-0"]{content:"jkkBkE,"}/*!sc*/
.fznaMn{position:-webkit-sticky;position:sticky;top:0;left:0;right:0;box-shadow:0 2px 3px rgba(71,75,255,0.075);z-index:100;background:#ffffff;}/*!sc*/
@supports (-webkit-backdrop-filter:saturate(180%) blur(20px)) or (backdrop-filter:saturate(180%) blur(20px)){.fznaMn{background:rgba(255,255,255,1);-webkit-backdrop-filter:saturate(180%) blur(20px);backdrop-filter:saturate(180%) blur(20px);}}/*!sc*/
data-styled.g33[id="sc-277ce8cb-1"]{content:"fznaMn,"}/*!sc*/
.fOCdQW{width:100%;height:64px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:0 40px 0 24px;}/*!sc*/
@media (max-width:1024px){.fOCdQW{padding:0 24px;}}/*!sc*/
@media (max-width:768px){.fOCdQW{height:56px;}}/*!sc*/
@media (max-width:414px){.fOCdQW{padding:0 16px;}}/*!sc*/
.fOCdQW .header-section.left{width:calc((2 / 11) * 100%);}/*!sc*/
@media (max-width:1640px){.fOCdQW .header-section.left{width:calc((1 / 11) * 100%);}}/*!sc*/
.fOCdQW .header-section.mid{width:calc((4 / 11) * 100%);}/*!sc*/
.fOCdQW .header-section.right{width:calc((5 / 11) * 100%);}/*!sc*/
@media (max-width:1640px){.fOCdQW .header-section.right{width:calc((6 / 11) * 100%);}}/*!sc*/
@media (max-width:1200px){.fOCdQW .header-section{width:auto !important;}.fOCdQW .header-section.mid{-webkit-flex:1;-ms-flex:1;flex:1;}}/*!sc*/
@media (max-width:1024px){.fOCdQW .header-section.left{-webkit-flex:1;-ms-flex:1;flex:1;}}/*!sc*/
.fOCdQW .section:nth-child(3){-webkit-order:3;-ms-flex-order:3;order:3;}/*!sc*/
@media (max-width:1024px){.fOCdQW .section:nth-child(3){-webkit-order:4;-ms-flex-order:4;order:4;}}/*!sc*/
.fOCdQW .section:nth-child(4){-webkit-order:4;-ms-flex-order:4;order:4;}/*!sc*/
@media (max-width:1024px){.fOCdQW .section:nth-child(4){-webkit-order:3;-ms-flex-order:3;order:3;}}/*!sc*/
data-styled.g34[id="sc-277ce8cb-2"]{content:"fOCdQW,"}/*!sc*/
.kfhbjy{display:none;}/*!sc*/
@media (max-width:1024px){.kfhbjy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;height:100%;}}/*!sc*/
data-styled.g36[id="sc-277ce8cb-4"]{content:"kfhbjy,"}/*!sc*/
.bUgJOQ{position:relative;overflow:hidden;width:unset;max-width:-webkit-max-content;max-width:-moz-max-content;max-width:max-content;height:48px;border-radius:24px;font-size:16px;font-weight:700;cursor:pointer;outline:none;-webkit-transition:0.25s;transition:0.25s;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0;white-space:nowrap;}/*!sc*/
@media (max-width:768px){.bUgJOQ{height:44px;}}/*!sc*/
.bUgJOQ .button-content{padding:0 16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;}/*!sc*/
.bUgJOQ .button-content .button-icon{margin:0 2px;font-size:17px;color:inherit;}/*!sc*/
.bUgJOQ .button-content .button-icon-right{margin-left:-4px;color:inherit;}/*!sc*/
.bUgJOQ .button-content .button-text{position:relative;margin:0 6px;white-space:nowrap;color:inherit;line-height:1;z-index:1;pointer-events:none;}/*!sc*/
.bUgJOQ .button-content .loading{position:absolute;left:50%;-webkit-transform:translate(-50%,0);-ms-transform:translate(-50%,0);transform:translate(-50%,0);color:inherit;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}/*!sc*/
.bUgJOQ .button-content .loading .spinner{margin:0 2px;}/*!sc*/
.bUgJOQ .button-content .loading .spinner .path{stroke:#ffffff;}/*!sc*/
.bUgJOQ .button-content .loading .loading-text{margin:0 6px;}/*!sc*/
.bUgJOQ.primary{background-color:#474bff;border:1px solid #474bff;color:#ffffff;}/*!sc*/
.bUgJOQ.primary:hover{background:#3236fd;border-color:#595cfb;}/*!sc*/
.bUgJOQ.primary .loading .spinner .path{stroke:#ffffff;}/*!sc*/
.bUgJOQ.secondary{background-color:#ffffff;color:#474bff;border:1px solid #474bff;}/*!sc*/
.bUgJOQ.secondary:hover{border-color:#3236fd;color:#3236fd;}/*!sc*/
.bUgJOQ.secondary .loading .spinner .path{stroke:#474bff;}/*!sc*/
.bUgJOQ.tertiary{background-color:#ffffff;color:#636388;border:1px solid #bbbbde;}/*!sc*/
.bUgJOQ.tertiary:hover{border-color:#a5a5cc;color:#26274b;}/*!sc*/
.bUgJOQ.tertiary .loading .spinner .path{stroke:#636388;}/*!sc*/
.bUgJOQ.disabled{pointer-events:none;opacity:0.5;}/*!sc*/
.bUgJOQ.midi{height:36px;border-radius:18px;font-weight:500;}/*!sc*/
.bUgJOQ.midi .button-content{font-size:14px;padding:0 16px;}/*!sc*/
.bUgJOQ.midi .button-content .button-text{margin:0 4px;}/*!sc*/
.bUgJOQ.midi .button-content .button-icon{font-size:16px;}/*!sc*/
.bUgJOQ.mini{height:24px;border-radius:12px;font-weight:500;}/*!sc*/
.bUgJOQ.mini .button-content{font-size:12px;padding:0 8px;}/*!sc*/
.bUgJOQ.mini .button-content .button-text{margin:0 2px;}/*!sc*/
.bUgJOQ.mini .button-content .button-icon{font-size:14px;}/*!sc*/
data-styled.g40[id="sc-f0c4711b-1"]{content:"bUgJOQ,"}/*!sc*/
.kZqBWo{position:relative;min-height:calc(100vh - 220px);}/*!sc*/
data-styled.g43[id="sc-115bd4ad-0"]{content:"kZqBWo,"}/*!sc*/
.eXdsIy{width:100%;margin:24px auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}/*!sc*/
.eXdsIy .tool-home,.eXdsIy .tool-category{width:970px;height:250px;}/*!sc*/
@media (max-width:1200px){.eXdsIy .tool-home,.eXdsIy .tool-category{width:728px;height:90px;}}/*!sc*/
@media (max-width:768px){.eXdsIy .tool-home,.eXdsIy .tool-category{width:336px;height:280px;}}/*!sc*/
@media (max-width:375px){.eXdsIy .tool-home,.eXdsIy .tool-category{width:300px;height:250px;}}/*!sc*/
.eXdsIy .tool-home-inner{width:728px;height:90px;}/*!sc*/
@media (max-width:768px){.eXdsIy .tool-home-inner{width:336px;height:280px;}}/*!sc*/
@media (max-width:375px){.eXdsIy .tool-home-inner{width:300px;height:250px;}}/*!sc*/
.eXdsIy .tool-top{width:728px;height:90px;}/*!sc*/
@media (max-width:768px){.eXdsIy .tool-top{width:320px;height:100px;}}/*!sc*/
.eXdsIy .tool-mid{width:728px;height:90px;}/*!sc*/
@media (max-width:768px){.eXdsIy .tool-mid{width:336px;height:280px;}}/*!sc*/
@media (max-width:375px){.eXdsIy .tool-mid{width:300px;height:250px;}}/*!sc*/
.eXdsIy .tool-bottom{width:728px;height:90px;}/*!sc*/
@media (max-width:768px){.eXdsIy .tool-bottom{width:336px;height:280px;}}/*!sc*/
@media (max-width:375px){.eXdsIy .tool-bottom{width:300px;height:250px;}}/*!sc*/
.eXdsIy iframe{background-color:#f1f2fb;}/*!sc*/
data-styled.g50[id="sc-16231449-0"]{content:"eXdsIy,"}/*!sc*/
.efFTsC .button{height:32px;border-radius:16px;}/*!sc*/
.efFTsC .button .button-content{font-size:13px;padding:0 12px;}/*!sc*/
@media (max-width:1024px){.efFTsC .button .button-content{font-size:12px;padding:0 8px;}}/*!sc*/
@media (max-width:414px){.efFTsC .button .button-text{margin-left:2px;}}/*!sc*/
data-styled.g56[id="sc-c6aa5ac6-0"]{content:"efFTsC,"}/*!sc*/
.fKPpMv{cursor:pointer;}/*!sc*/
.fKPpMv *{pointer-events:none;}/*!sc*/
data-styled.g57[id="sc-4d777a5-0"]{content:"fKPpMv,"}/*!sc*/
.dNZJWB{position:relative;width:100%;height:100%;}/*!sc*/
.dNZJWB .protector{position:absolute;bottom:24px;right:24px;z-index:2;}/*!sc*/
.dNZJWB .tool-bookmark{background-color:transparent;border:none;padding:0;color:#e1e1ef;cursor:pointer;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:24px;height:24px;z-index:2;}/*!sc*/
.dNZJWB .tool-bookmark.logged-in{position:absolute;bottom:24px;right:24px;z-index:2;}/*!sc*/
.dNZJWB .tool-bookmark .notice{position:absolute;font-size:12px;right:calc(100% + 16px);top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);opacity:0;pointer-events:none;-webkit-transition:0.25s;transition:0.25s;padding:8px;background-color:rgba(71,75,255,0.1);color:#474bff;border-radius:8px;font-weight:600;white-space:nowrap;}/*!sc*/
.dNZJWB .tool-bookmark .notice:before{content:'';position:absolute;left:100%;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);width:0;height:0;border-style:solid;border-width:5px 0 5px 10px;border-color:transparent transparent transparent #f1f2fb;}/*!sc*/
.dNZJWB .tool-bookmark:hover .notice{opacity:1;}/*!sc*/
.dNZJWB .tool-bookmark:hover,.dNZJWB .tool-bookmark.fav{color:#474bff;}/*!sc*/
.dNZJWB .tool-bookmark i{font-size:24px;}/*!sc*/
.dNZJWB .tool-bookmark .circle{position:absolute;width:18px;height:18px;background:rgba(71,75,255,0.1);top:50%;left:50%;-webkit-transform:translate(-50%,calc(-50% - 1px));-ms-transform:translate(-50%,calc(-50% - 1px));transform:translate(-50%,calc(-50% - 1px));-webkit-transition:0.25s cubic-bezier(0.4,0,0.2,1);transition:0.25s cubic-bezier(0.4,0,0.2,1);opacity:0;border-radius:50%;overflow:hidden;}/*!sc*/
.dNZJWB .tool-bookmark:hover .circle{width:36px;height:36px;opacity:1;}/*!sc*/
data-styled.g58[id="sc-2032de01-0"]{content:"dNZJWB,"}/*!sc*/
.dBgShE{position:relative;width:100%;height:100%;padding:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;border-radius:8px;box-shadow:none;-webkit-transition:0.25s -webkit-transform,0.25s box-shadow;-webkit-transition:0.25s transform,0.25s box-shadow;transition:0.25s transform,0.25s box-shadow;overflow:hidden;box-shadow:0 2px 3px rgba(71,75,255,0.075);background:#ffffff;z-index:1;cursor:pointer;}/*!sc*/
.dBgShE:hover{box-shadow:0 8px 12px rgba(71,75,255,0.075);}/*!sc*/
.dBgShE .tool-icon{width:auto;max-width:-webkit-max-content;max-width:-moz-max-content;max-width:max-content;height:42px;margin:0 0 16px;}/*!sc*/
.dBgShE .tool-name{font-family:Space Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;font-size:17px;font-weight:700;}/*!sc*/
.dBgShE .tool-description{font-size:15px;color:#636388;width:100%;line-height:1.5;margin-top:8px;}/*!sc*/
.dBgShE .tool-open{margin-top:16px;font-size:14px;color:#474bff;}/*!sc*/
.dBgShE .tool-open:hover{font-weight:500;}/*!sc*/
data-styled.g59[id="sc-2032de01-1"]{content:"dBgShE,"}/*!sc*/
.krvkWw{-webkit-flex:1;-ms-flex:1;flex:1;width:100%;height:100%;background-color:transparent;color:#26274b;border:none;padding:27px 16px 9px;overflow:hidden;}/*!sc*/
.krvkWw:hover,.krvkWw:focus,.krvkWw:active{outline:none;}/*!sc*/
.krvkWw::-webkit-input-placeholder{color:rgba(115,115,143,0.75);}/*!sc*/
.krvkWw::-moz-placeholder{color:rgba(115,115,143,0.75);}/*!sc*/
.krvkWw:-ms-input-placeholder{color:rgba(115,115,143,0.75);}/*!sc*/
.krvkWw::placeholder{color:rgba(115,115,143,0.75);}/*!sc*/
data-styled.g93[id="sc-66b5ec4-1"]{content:"krvkWw,"}/*!sc*/
.dlSmZD{height:27px;line-height:27px;position:absolute;top:50%;left:16px;right:16px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);font-size:16px;color:#73738f;pointer-events:none;-webkit-transition:0.15s;transition:0.15s;}/*!sc*/
.dlSmZD .required{color:#ff003d;}/*!sc*/
data-styled.g94[id="sc-66b5ec4-2"]{content:"dlSmZD,"}/*!sc*/
.etUJBb{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:56px;border-radius:8px;background:#ffffff;border:1px solid #e1e1ef;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.etUJBb.disabled{opacity:0.5;}/*!sc*/
.etUJBb.disabled:before{content:'';position:absolute;top:0;bottom:0;left:0;right:0;z-index:1;cursor:not-allowed;}/*!sc*/
.etUJBb.filled .sc-66b5ec4-2,.etUJBb:not(.read-only) input:focus + .sc-66b5ec4-2{top:0;font-size:12px;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);}/*!sc*/
.etUJBb.error{border:1px solid rgba(255,0,61,0.5);}/*!sc*/
.etUJBb.error .sc-66b5ec4-0{color:#ff003d;}/*!sc*/
.etUJBb:focus-within:not(.error):not(.read-only){border:1px solid rgba(71,75,255,0.5);box-shadow:0 0 0 2px rgba(71,75,255,0.1);}/*!sc*/
.etUJBb:not(.filled) input{color:transparent;}/*!sc*/
.etUJBb:not(.filled) input:focus{color:#26274b;}/*!sc*/
.etUJBb .input-wrapper{width:100%;height:100%;position:relative;}/*!sc*/
.etUJBb .input-button{height:40px;margin-right:8px;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;}/*!sc*/
.etUJBb .input-button .button-content{padding:0 16px;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;}/*!sc*/
.etUJBb.mono input{font-family:monospace;page-break-inside:avoid;overflow:auto;word-wrap:break-word;word-break:break-all;}/*!sc*/
.etUJBb.has-icon .sc-66b5ec4-2{left:48px;}/*!sc*/
.etUJBb.has-icon .sc-66b5ec4-1{padding-left:48px;}/*!sc*/
data-styled.g95[id="sc-66b5ec4-3"]{content:"etUJBb,"}/*!sc*/
.cFbtvD{position:absolute;color:#ff003d;font-size:10px;top:100%;}/*!sc*/
data-styled.g96[id="sc-66b5ec4-4"]{content:"cFbtvD,"}/*!sc*/
.gDZKrq{word-break:break-word;}/*!sc*/
.gDZKrq h2,.gDZKrq h3{padding-top:8px;}/*!sc*/
.gDZKrq a{color:#474bff;font-weight:600;background-repeat:no-repeat;background-position:right 6px;padding-right:14px;background-size:10px;}/*!sc*/
.gDZKrq .image{margin:24px auto;}/*!sc*/
.gDZKrq .image img{margin:0 auto;display:block;max-width:100%;box-shadow:0 2px 4px rgba(71,75,255,0.15);}/*!sc*/
.gDZKrq .image .alt{margin:8px auto 0;text-align:center;color:#636388;font-size:14px;}/*!sc*/
.gDZKrq b{font-weight:600;}/*!sc*/
.gDZKrq strong{font-weight:600;background-image:linear-gradient(0deg,rgba(71,75,255,0.3),rgba(71,75,255,0.3));background-size:4px 4px;background-repeat:repeat-x;background-position:center bottom;}/*!sc*/
.gDZKrq ul{margin:16px 0;padding-left:24px;}/*!sc*/
.gDZKrq ul li{list-style-type:disc;}/*!sc*/
.gDZKrq ul li + li{margin-top:8px;}/*!sc*/
.gDZKrq ol{margin:16px 0;padding-left:24px;list-style:none;counter-reset:counter;}/*!sc*/
.gDZKrq ol li{position:relative;counter-increment:counter;}/*!sc*/
.gDZKrq ol li::before{content:counter(counter) '.';position:absolute;left:-24px;top:6px;width:16px;height:16px;font-weight:600;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}/*!sc*/
.gDZKrq ol li + li{margin-top:8px;}/*!sc*/
data-styled.g130[id="sc-cb982ab5-0"]{content:"gDZKrq,"}/*!sc*/
.dKhYLg{position:fixed;top:64px;bottom:0;left:0;width:360px;max-width:360px;background:#ffffff;border-right:1px solid #e1e1ef;}/*!sc*/
@media (max-width:1280px){.dKhYLg{display:none;}}/*!sc*/
data-styled.g132[id="sc-390cf866-0"]{content:"dKhYLg,"}/*!sc*/
.bARNXv{position:absolute;top:0;left:0;right:0;bottom:0;}/*!sc*/
.bARNXv h3{padding:16px 24px 12px;margin:0;color:#636388;font-weight:400;text-transform:uppercase;-webkit-letter-spacing:2px;-moz-letter-spacing:2px;-ms-letter-spacing:2px;letter-spacing:2px;font-size:12px;background:#f1f2fb;}/*!sc*/
.bARNXv .rc-collapse{position:absolute;top:43px;left:0;right:0;bottom:0;background-color:#ffffff;border-color:rgba(225,225,239,0.25);border-top:1px solid rgba(225,225,239,0.5);border-left:none;border-right:none;border-radius:0;overflow-x:hidden;overflow-y:auto;}/*!sc*/
.bARNXv .rc-collapse::-webkit-scrollbar{width:6px;height:6px;}/*!sc*/
.bARNXv .rc-collapse::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 8px rgba(71,75,255,0.2);-webkit-border-radius:0;border-radius:0;}/*!sc*/
.bARNXv .rc-collapse::-webkit-scrollbar-thumb{-webkit-border-radius:8px;border-radius:8px;background:rgba(71,75,255,0.1);-webkit-box-shadow:inset 0 0 8px rgba(71,75,255,0.1);}/*!sc*/
.bARNXv .rc-collapse::-webkit-scrollbar-thumb:window-inactive{background:rgba(71,75,255,0.3);}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item{border-color:rgba(225,225,239,0.25);border-right:none;border-left:none;}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item:last-child{border-bottom:1px solid rgba(225,225,239,0.25);}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item .rc-collapse-header{padding:12px 16px;-webkit-transition:0.2s;transition:0.2s;outline:none !important;font-family:Space Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;font-weight:500;position:relative;color:#26274b;font-size:17px;border-bottom:1px solid transparent;}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-expand-icon{position:absolute;right:24px;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);line-height:1;margin-top:1px;}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-expand-icon i{font-size:18px;-webkit-transition:0.2s -webkit-transform;-webkit-transition:0.2s transform;transition:0.2s transform;}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-expand-icon i.active{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item .rc-collapse-content{background-color:rgba(248,249,251,0.1);box-shadow:inset 0 4px 8px rgba(225,225,239,0.25), inset 0 -4px 8px rgba(225,225,239,0.25);padding:0;border-radius:0;}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item .rc-collapse-content .rc-collapse-content-box{margin:0;}/*!sc*/
.bARNXv .rc-collapse .rc-collapse-item.rc-collapse-item-active .rc-collapse-header{color:#474bff;font-weight:600;border-bottom:1px solid rgba(225,225,239,0.25);}/*!sc*/
.bARNXv .category-icon{width:40px;height:40px;margin-right:6px;}/*!sc*/
.bARNXv .category-tools{position:relative;padding:8px 0;}/*!sc*/
.bARNXv .category-tools:before{content:'';position:absolute;width:1px;height:100%;background:#e1e1ef;left:35px;top:0;}/*!sc*/
.bARNXv .category-tools .category-tool{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;padding:12px 32px 12px 50px;-webkit-transition:0.25s background-color,0.25s color;transition:0.25s background-color,0.25s color;cursor:pointer;}/*!sc*/
.bARNXv .category-tools .category-tool .tool-icon{width:28px;min-width:28px;height:20px;margin-right:2px;}/*!sc*/
.bARNXv .category-tools .category-tool .tool-icon img{height:20px;-webkit-filter:grayscale(100%);filter:grayscale(100%);-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.bARNXv .category-tools .category-tool .tool-name{white-space:nowrap;font-size:15px;color:#26274b;}/*!sc*/
.bARNXv .category-tools .category-tool:focus,.bARNXv .category-tools .category-tool:hover{background-color:rgba(241,242,251,0.4);outline:none;}/*!sc*/
.bARNXv .category-tools .category-tool:focus .tool-name,.bARNXv .category-tools .category-tool:hover .tool-name{color:#26274b;}/*!sc*/
.bARNXv .category-tools .category-tool.active{cursor:default;pointer-events:none;color:#26274b;outline:none;}/*!sc*/
.bARNXv .category-tools .category-tool.active:before{content:'';width:3px;position:absolute;top:0;left:34px;bottom:0;background:#474bff;}/*!sc*/
.bARNXv .category-tools .category-tool.active .tool-name{font-weight:700;}/*!sc*/
.bARNXv .category-tools .category-tool.active .tool-icon img{-webkit-filter:grayscale(0);filter:grayscale(0);}/*!sc*/
.bARNXv .favorite-tools .unauthanticated,.bARNXv .favorite-tools .no-favorited{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;font-size:15px;padding:24px;text-align:center;color:#636388;}/*!sc*/
.bARNXv .favorite-tools .unauthanticated i,.bARNXv .favorite-tools .no-favorited i{font-size:64px;margin-bottom:12px;color:#bbbbde;}/*!sc*/
.bARNXv .favorite-tools .unauthanticated .sign-in,.bARNXv .favorite-tools .no-favorited .sign-in{font-weight:700;color:#474bff;cursor:pointer;}/*!sc*/
data-styled.g133[id="sc-390cf866-1"]{content:"bARNXv,"}/*!sc*/
.hWbIlo{position:fixed;top:64px;left:360px;bottom:0;right:0;overflow-y:auto;}/*!sc*/
@media (max-width:1280px){.hWbIlo{left:0;}}/*!sc*/
.hWbIlo .similar-tools-header h2{margin:0;}/*!sc*/
.hWbIlo .similar-tools-header .similar-tools{font-size:16px;font-weight:600;color:#474bff;-webkit-transition:0.2s;transition:0.2s;}/*!sc*/
.hWbIlo .similar-tools-header .similar-tools:hover{color:#3236fd;}/*!sc*/
data-styled.g135[id="sc-67590e64-1"]{content:"hWbIlo,"}/*!sc*/
.hEdAXO{background-color:#ffffff;padding:32px;margin-top:40px;border-radius:8px;box-shadow:0 2px 3px rgba(71,75,255,0.075);}/*!sc*/
@media (max-width:1024px){.hEdAXO{margin-top:24px;}}/*!sc*/
@media (max-width:768px){.hEdAXO{padding:24px;}}/*!sc*/
data-styled.g136[id="sc-67590e64-2"]{content:"hEdAXO,"}/*!sc*/
.hqlRAN{position:relative;margin-bottom:24px;}/*!sc*/
@media (max-width:768px){.hqlRAN{margin-bottom:16px;}}/*!sc*/
.hqlRAN img{height:32px;padding-right:12px;}/*!sc*/
@media (max-width:768px){.hqlRAN img{height:27px;}}/*!sc*/
.hqlRAN h1{font-size:26px;margin:0;padding-right:16px;}/*!sc*/
@media (max-width:414px){.hqlRAN h1{font-size:20px;}}/*!sc*/
@media (max-width:375px){.hqlRAN h1{font-size:18px;}}/*!sc*/
.hqlRAN h1 a{color:#26274b;}/*!sc*/
.hqlRAN .actions{min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;}/*!sc*/
.hqlRAN .button{height:32px;border-radius:16px;}/*!sc*/
.hqlRAN .button .button-content{font-size:13px;padding:0 12px;}/*!sc*/
@media (max-width:1024px){.hqlRAN .button .button-content{padding:0 8px;}}/*!sc*/
@media (max-width:414px){.hqlRAN .button .button-content{padding:0 6px;}}/*!sc*/
@media (max-width:414px){.hqlRAN .button .button-text{margin-left:2px !important;}}/*!sc*/
data-styled.g137[id="sc-67590e64-3"]{content:"hqlRAN,"}/*!sc*/
.eLWmgB{position:relative;}/*!sc*/
.eLWmgB .main-text{position:relative;top:0;opacity:1;-webkit-transition:0.2s;transition:0.2s;}/*!sc*/
.eLWmgB .main-text i{font-size:18px;margin-right:6px;}/*!sc*/
.eLWmgB .copied{position:absolute;top:200%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);opacity:0;-webkit-transition:0.2s;transition:0.2s;}/*!sc*/
.eLWmgB .copied i{font-size:18px;margin-right:6px;}/*!sc*/
data-styled.g141[id="sc-7c6bbe43-0"]{content:"eLWmgB,"}/*!sc*/
.ewBjOX{position:relative;width:100%;height:56px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
@media (max-width:768px){.ewBjOX{height:40px;}}/*!sc*/
.ewBjOX.inline{width:auto;height:auto;}/*!sc*/
.ewBjOX.disabled{opacity:0.5;pointer-events:none;}/*!sc*/
data-styled.g146[id="sc-df695e7d-0"]{content:"ewBjOX,"}/*!sc*/
.jtxtLR{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;max-width:-webkit-max-content;max-width:-moz-max-content;max-width:max-content;cursor:pointer;}/*!sc*/
.jtxtLR.disabled{opacity:0.5;pointer-events:none;}/*!sc*/
.jtxtLR .checkbox-text{margin-left:10px;position:relative;top:1px;white-space:nowrap;}/*!sc*/
.jtxtLR .checkbox-text .required{color:#ff003d;}/*!sc*/
data-styled.g147[id="sc-df695e7d-1"]{content:"jtxtLR,"}/*!sc*/
.dfseoB{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}/*!sc*/
data-styled.g148[id="sc-df695e7d-2"]{content:"dfseoB,"}/*!sc*/
.gtGeXx{fill:none;stroke:white;stroke-width:2px;position:absolute;top:0;bottom:0;left:0;right:0;}/*!sc*/
data-styled.g149[id="sc-df695e7d-3"]{content:"gtGeXx,"}/*!sc*/
.hWpFrQ{display:none;}/*!sc*/
data-styled.g150[id="sc-df695e7d-4"]{content:"hWpFrQ,"}/*!sc*/
.dXuyOW{position:relative;width:18px;height:18px;color:rgba(71,75,255,0.2);background:#ffffff;border:2px solid #e1e1ef;border-radius:4px;margin-top:2px;}/*!sc*/
.dXuyOW .circle{position:absolute;width:18px;height:18px;background:rgba(71,75,255,0.1);top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-webkit-transition:0.25s cubic-bezier(0.4,0,0.2,1);transition:0.25s cubic-bezier(0.4,0,0.2,1);opacity:0;border-radius:50%;overflow:hidden;}/*!sc*/
.dXuyOW:hover .circle{width:36px;height:36px;opacity:1;}/*!sc*/
.dXuyOW .sc-df695e7d-3{visibility:visible;}/*!sc*/
.gyUVfG{position:relative;width:18px;height:18px;color:rgba(71,75,255,0.2);background:#ffffff;border:2px solid #e1e1ef;border-radius:4px;margin-top:2px;}/*!sc*/
.gyUVfG .circle{position:absolute;width:18px;height:18px;background:rgba(71,75,255,0.1);top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-webkit-transition:0.25s cubic-bezier(0.4,0,0.2,1);transition:0.25s cubic-bezier(0.4,0,0.2,1);opacity:0;border-radius:50%;overflow:hidden;}/*!sc*/
.gyUVfG:hover .circle{width:36px;height:36px;opacity:1;}/*!sc*/
.gyUVfG .sc-df695e7d-3{visibility:hidden;}/*!sc*/
data-styled.g151[id="sc-df695e7d-5"]{content:"dXuyOW,gyUVfG,"}/*!sc*/
.dmWeei{position:absolute;color:#ff003d;font-size:10px;top:100%;}/*!sc*/
data-styled.g152[id="sc-df695e7d-6"]{content:"dmWeei,"}/*!sc*/
.beOUVL{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;max-width:-webkit-max-content;max-width:-moz-max-content;max-width:max-content;cursor:pointer;}/*!sc*/
.beOUVL.disabled{opacity:0.5;pointer-events:none;}/*!sc*/
.beOUVL .radio-text{margin-left:10px;white-space:nowrap;position:relative;top:1px;}/*!sc*/
data-styled.g176[id="sc-fca10b6b-0"]{content:"beOUVL,"}/*!sc*/
.eUnkIo{position:relative;height:56px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}/*!sc*/
@media (max-width:768px){.eUnkIo{height:40px;}}/*!sc*/
.eUnkIo.disabled{opacity:0.5;pointer-events:none;}/*!sc*/
.eUnkIo.inline{width:auto;height:auto;}/*!sc*/
.eUnkIo .sc-fca10b6b-0 + .sc-fca10b6b-0{margin-left:32px;}/*!sc*/
@media (max-width:768px){.eUnkIo .sc-fca10b6b-0 + .sc-fca10b6b-0{margin-left:16px;}}/*!sc*/
data-styled.g177[id="sc-fca10b6b-1"]{content:"eUnkIo,"}/*!sc*/
.bQQSCf{display:none;}/*!sc*/
data-styled.g178[id="sc-fca10b6b-2"]{content:"bQQSCf,"}/*!sc*/
.hNyWHe{position:relative;width:18px;height:18px;color:rgba(71,75,255,0.8);background:#474bff;border:none;border-radius:50%;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.hNyWHe:before{content:'';width:8px;height:8px;border-radius:50%;background:white;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);opacity:1;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.hNyWHe .circle{position:absolute;width:18px;height:18px;background:rgba(71,75,255,0.1);top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-webkit-transition:0.25s cubic-bezier(0.4,0,0.2,1);transition:0.25s cubic-bezier(0.4,0,0.2,1);opacity:0;border-radius:50%;overflow:hidden;}/*!sc*/
.hNyWHe:hover .circle{width:36px;height:36px;opacity:1;}/*!sc*/
.fYwzzD{position:relative;width:18px;height:18px;color:rgba(71,75,255,0.8);background:#ffffff;border:2px solid #e1e1ef;border-radius:50%;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.fYwzzD:before{content:'';width:8px;height:8px;border-radius:50%;background:white;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);opacity:0;-webkit-transition:0.25s;transition:0.25s;}/*!sc*/
.fYwzzD .circle{position:absolute;width:18px;height:18px;background:rgba(71,75,255,0.1);top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-webkit-transition:0.25s cubic-bezier(0.4,0,0.2,1);transition:0.25s cubic-bezier(0.4,0,0.2,1);opacity:0;border-radius:50%;overflow:hidden;}/*!sc*/
.fYwzzD:hover .circle{width:36px;height:36px;opacity:1;}/*!sc*/
data-styled.g179[id="sc-fca10b6b-3"]{content:"hNyWHe,fYwzzD,"}/*!sc*/
.fXXuTy{width:100%;}/*!sc*/
.fXXuTy input{font-weight:700;-webkit-letter-spacing:0.1px;-moz-letter-spacing:0.1px;-ms-letter-spacing:0.1px;letter-spacing:0.1px;}/*!sc*/
@media (max-width:768px){.fXXuTy .desktop-label{display:none;}}/*!sc*/
data-styled.g320[id="sc-13fb4d17-0"]{content:"fXXuTy,"}/*!sc*/
@-webkit-keyframes OBGVY{0%{-webkit-transform:rotate(10deg);-ms-transform:rotate(10deg);transform:rotate(10deg);}50%{-webkit-transform:rotate(-10deg);-ms-transform:rotate(-10deg);transform:rotate(-10deg);}100%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}}/*!sc*/
@keyframes OBGVY{0%{-webkit-transform:rotate(10deg);-ms-transform:rotate(10deg);transform:rotate(10deg);}50%{-webkit-transform:rotate(-10deg);-ms-transform:rotate(-10deg);transform:rotate(-10deg);}100%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}}/*!sc*/
data-styled.g323[id="sc-keyframes-OBGVY"]{content:"OBGVY,"}/*!sc*/
@-webkit-keyframes kyMnUB{0%{background-position:-1000px 0;}100%{background-position:1000px 0;}}/*!sc*/
@keyframes kyMnUB{0%{background-position:-1000px 0;}100%{background-position:1000px 0;}}/*!sc*/
data-styled.g324[id="sc-keyframes-kyMnUB"]{content:"kyMnUB,"}/*!sc*/
.rc-collapse-motion {
    transition:height .3s,opacity .3s
  }
  .rc-collapse-content-hidden {
    display:none
  }
  .rc-collapse {
    background-color:#f7f7f7;
    border-radius:3px;
    border:1px solid #d9d9d9
  }
  .rc-collapse>.rc-collapse-item {
    border-top:1px solid #d9d9d9
  }
  .rc-collapse>.rc-collapse-item:first-child {
    border-top:none
  }
  .rc-collapse>.rc-collapse-item>.rc-collapse-header {
    display:flex;
    align-items:center;
    line-height:22px;
    padding:10px 16px;
    color:#666;
    cursor:pointer
  }
  .rc-collapse>.rc-collapse-item>.rc-collapse-header .arrow {
    display:inline-block;
    content:"\20";
    width:0;
    height:0;
    font-size:0;
    line-height:0;
    border-top:3px solid transparent;
    border-bottom:3px solid transparent;
    border-left:4px solid #666;
    vertical-align:middle;
    margin-right:8px
  }
  .rc-collapse>.rc-collapse-item>.rc-collapse-header .rc-collapse-extra {
    margin:0 16px 0 auto
  }
  .rc-collapse>.rc-collapse-item .rc-collapse-header-collapsible-only {
    cursor:default
  }
  .rc-collapse>.rc-collapse-item .rc-collapse-header-collapsible-only .rc-collapse-expand-icon,
  .rc-collapse>.rc-collapse-item .rc-collapse-header-collapsible-only .rc-collapse-header-text {
    cursor:pointer
  }
  .rc-collapse>.rc-collapse-item .rc-collapse-icon-collapsible-only {
    cursor:default
  }
  .rc-collapse>.rc-collapse-item .rc-collapse-icon-collapsible-only .rc-collapse-expand-icon {
    cursor:pointer
  }
  .rc-collapse>.rc-collapse-item-disabled>.rc-collapse-header {
    cursor:not-allowed;
    color:#999;
    background-color:#f3f3f3
  }
  .rc-collapse-content {
    overflow:hidden;
    color:#666;
    padding:0 16px;
    background-color:#fff
  }
  .rc-collapse-content>.rc-collapse-content-box {
    margin-top:16px;
    margin-bottom:16px
  }
  .rc-collapse-item:last-child>.rc-collapse-content {
    border-radius:0 0 3px 3px
  }
  .rc-collapse>.rc-collapse-item-active>.rc-collapse-header .arrow {
    position:relative;
    top:2px;
    border-left:3px solid transparent;
    border-right:3px solid transparent;
    border-top:4px solid #666;
    margin-right:6px
  }
  